if ($('.l-headerHome').length) {
    var swiper__headerHome = new Swiper ('.swiper__banner', {
        speed: 400,
        spaceBetween: 0,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper__banner .swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: { 
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    })
} 

var swiper__examesHome = new Swiper ('.swiper__exames', {
    speed: 400,
    spaceBetween: 15,
    slidesPerView: 3,
    pagination: {
        el: '.swiper__exames .swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    
    breakpoints: {
        // when window width is >= 320px
        400: {
            slidesPerView: 1
        },
        580: {
            slidesPerView: 2
        },
    },
})